











































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Form } from "element-ui";
import {
  ApiLabelList,
  ApiLabelAdd,
  ApiLabelEdit,
  ApiLabelDelete,
} from "@/apis/index";
import { ElStep } from "element-ui/types/step";
@Component({})
export default class LabelManagelist extends Vue {
  tableData: Array<any> = [];
  pagination = {
    //当前是第几页
    page: 1,
    //分页器当前页显示几条数据
    size: 10,
    //表格的数据总量
    total: 0,
  };
  formDialogRules = {
    name: [{ required: true, message: "请输入分组名称", trigger: "blur" }],
    color: [{ required: true, message: "请选择分组颜色", trigger: "blur" }],
  };
  //hook
  mounted() {
    this.init();
  }
  //methods
  init() {
    this.updateTableData();
  }
  //获取列表数据
  updateTableData() {
    const param = {
      cur_page: this.pagination.page,
      page_size: this.pagination.size,
    };
    ApiLabelList(param).then((res) => {
      if (res.success) {
        this.tableData = res.data;
        this.pagination.total = res.page.total_size;
      }
    });
  }
  //点击查询按钮触发的事件
  clickSearch() {
    this.updateTableData();
  }
  //点击重置按钮触发的事件
  clickReset() {
    //重置分页器
    this.pagination.page = 1;
    this.pagination.size = 10;
    //重置表格数据
    this.updateTableData();
  }
  //分页器改变页显示数触发函数
  handleSizeChange(val: any) {
    this.pagination.size = val;
    this.updateTableData();
  }
  //分页器改变当前显示页触发函数
  handleCurrentChange(val: any) {
    this.pagination.page = val;
    this.updateTableData();
  }
  //点击新增按钮触发
  clickAdd() {
    this.showFormDialog = true;
  }
  //点击编辑按钮触发
  clickEdit(row: any) {
    this.showFormDialog = true;
    this.isEditDialog = true;
    this.currentRow = row;
    //把这一条信息回显到编辑窗口
    for (const item in this.dialogForm) {
      this.dialogForm[item] = row[item];
    }
  }
  //对话框表单内容
  dialogForm: any = {
    name: "",
    color: "",
    description: "",
  };
  //是否打开新增、编辑对话框
  showFormDialog = false;
  //默认是新增对话框，如果点击编辑是编辑对话框
  isEditDialog = false;
  //编辑的某一项数据
  currentRow: any = null;
  //关闭表单对话框函数
  closeFormDialog() {
    this.showFormDialog = false;
    this.currentRow = null;
    this.isEditDialog = false;
    //重置表单内容
    this.resetForm(this.dialogForm);
    //重置表单校验效果
    const form = this.$refs["ruleForm"] as any;
    form.clearValidate();
  }
  //重置表单数据函数
  resetForm(obj: any) {
    for (const item in obj) {
      obj[item] = "";
    }
    this.dialogForm = obj;
  }
  //表单对话框确定按钮点击触发
  completeFormConfirm() {
    const form = this.$refs["ruleForm"] as Form;
    form.validate((valid) => {
      if (valid) {
        if (this.isEditDialog) {
          const param = {
            id: this.currentRow.id,
            before_name: this.currentRow.name,
            before_color: this.currentRow.color,
            ...this.dialogForm,
          };
          ApiLabelEdit(param).then((res) => {
            if (res.success) {
              this.updateTableData();
              this.closeFormDialog();
            }
          });
        } else {
          const param = {
            ...this.dialogForm,
          };
          ApiLabelAdd(param).then((res) => {
            if (res.success) {
              this.updateTableData();
              this.closeFormDialog();
            }
          });
        }
      }
    });
  }
  //表单对话框继续添加按钮点击触发
  completeFormContinue() {
    const form = this.$refs["ruleForm"] as Form;
    form.validate((valid) => {
      if (valid) {
        const param = {
          ...this.dialogForm,
        };
        ApiLabelAdd(param).then((res) => {
          if (res.success) {
            this.updateTableData();
            //重置表单内容
            this.resetForm(this.dialogForm);
            //重置表单校验效果
            const form = this.$refs["ruleForm"] as any;
            form.clearValidate();
          }
        });
      }
    });
  }
  //点击删除按钮触发
  clickDelete(row: any) {
    this.showConfirmDialog = true;
    this.currentRow = row;
  }
  //确认对话框是否显示
  showConfirmDialog = false;
  //关闭确认对话框函数
  closeConfirmDialog() {
    this.showConfirmDialog = false;
  }
  //确认对话框确定按钮点击触发
  completeConfirmConfirm() {
    const param = {
      id: this.currentRow.id,
    };
    ApiLabelDelete(param).then((res) => {
      if (res.success) {
        this.updateTableData();
        this.closeConfirmDialog();
      }
    });
  }
}
